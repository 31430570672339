import React from "react";
import { useLocation } from "react-router-dom";
import { AddAddressForm } from "../../Components/AddAddressForm/AddAddressForm";
import { Footer } from "../../Components/Footer/Footer";
import { Header } from "../../Components/Header/Header";
import { PageHeader } from "../../Components/PageHeader/PageHeader";
import SeoDefault from "../../Components/Seo/SeoDefault";
import { useApp } from "../../context/AppContextProvider";
import { MyAccountMenu } from "../MyAccount/MyAccount";

export const AddAddress = () => {
  const appData = useApp();
  const location = useLocation();
  const addresState = location.state;
  let windowWidth = appData.appData.windowWidth;

  return (
    <React.Fragment>
      <SeoDefault
        title="Add New Address - Knick Knack"
        description="Add a new shipping address to your Knick Knack account."
        keywords="Knick Knack add address, shipping address, account setup"
        canonicalUrl="https://knickknack.online/add-new-address"
        pageType="website"
        robots="noindex"
        breadcrumbItems={[
          { name: "Home", url: "https://knickknack.online/" },
          { name: "My Address", url: "https://knickknack.online/my-address" },
          { name: "Add New Address", url: "https://knickknack.online/add-new-address" },
        ]}
      />
      {windowWidth === "mobile" ? (
        <React.Fragment>
          <PageHeader
            title={`${addresState.addressEdit === true ? "Edit Address" : "Add Address"
              }`}
          />
          <AddAddressForm />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Header />
          <div className="col-12 d-inline-flex mt-4">
            <div className="container">
              <div className="d-flex gap-3 col-12 align-items-start">
                <MyAccountMenu />
                <div className="w-full flex-grow-1">
                  <AddAddressForm />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
