import React, { useEffect } from "react";
import { Footer } from "../../Components/Footer/Footer";
import { Header } from "../../Components/Header/Header";
import { PageHeader } from "../../Components/PageHeader/PageHeader";
import SeoDefault from "../../Components/Seo/SeoDefault";
import { useApp } from "../../context/AppContextProvider";

export const Faq = () => {
  const appData = useApp();
  let windowWidth = appData.appData.windowWidth;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <SeoDefault
        title="FAQ - Knick Knack"
        description="Find answers to frequently asked questions about shopping at Knick Knack for children's toys."
        keywords="Knick Knack FAQ, toy store questions, customer support India"
        canonicalUrl="https://knickknack.online/faq"
        pageType="website"
        breadcrumbItems={[
          { name: "Home", url: "https://knickknack.online/" },
          { name: "FAQ", url: "https://knickknack.online/faq" },
        ]}
      />
      {windowWidth === "mobile" ? (
        <PageHeader title="Privacy" hide={true} />
      ) : (
        <Header />
      )}
      <div className="min-vh-100 col-12 d-inline-flex flex-column my-5">
        <div className="container"></div>
      </div>
      <Footer />
    </React.Fragment>
  );
};
