import React, { useEffect } from "react";
import { Footer } from "../../Components/Footer/Footer";
import { Header } from "../../Components/Header/Header";
import { PageHeader } from "../../Components/PageHeader/PageHeader";
import SeoDefault from "../../Components/Seo/SeoDefault";
import { useApp } from "../../context/AppContextProvider";

export const Press = () => {
  const appData = useApp();
  let windowWidth = appData.appData.windowWidth;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <SeoDefault
        title="Press - Knick Knack"
        description="Read the latest press releases and news about Knick Knack, your premium toy store in India."
        keywords="Knick Knack press, news, toy store India"
        canonicalUrl="https://knickknack.online/press"
        pageType="website"
        breadcrumbItems={[
          { name: "Home", url: "https://knickknack.online/" },
          { name: "Press", url: "https://knickknack.online/press" },
        ]}
      />
      {windowWidth === "mobile" ? (
        <PageHeader title="Privacy Policy" hide={true} />
      ) : (
        <Header />
      )}
      <div className="min-vh-100 col-12 d-inline-flex flex-column my-5">
        <div className="container"></div>
      </div>
      <Footer />
    </React.Fragment>
  );
};
